@import "~bootstrap/scss/bootstrap";
@import "font/feather";

@import "~meditrip-styles/normalize";
@import "~meditrip-styles/panel/theme-variables";
@import "~meditrip-styles/panel/style";
@import "~meditrip-styles/form-controls/style";
@import "~meditrip-styles/emoji/emoji";
@import "./datepicker/styles";

.event-form-container {
  form {
    width: 100%;
  }
}
